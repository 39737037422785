var render = function () {
  var _vm$boat_analytic_chi;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "body-class": "p-0 custom-card"
    }
  }, [_vm.fetchLoading ? _c('b-progress', {
    key: "info",
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": "100",
      "variant": "info"
    }
  }) : _vm._e(), _c('b-table', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary",
      modifiers: {
        "hover": true,
        "v-primary": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: _vm.disableEdit == false || _vm.disableEdit == null ? 'Untuk Mengubah Data, Klik 2x Pada Kolomnya' : '',
      expression: "\n          disableEdit == false || disableEdit == null\n            ? 'Untuk Mengubah Data, Klik 2x Pada Kolomnya'\n            : ''\n        ",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    ref: _vm.refSpecify || 'YoTable',
    staticClass: "m-0 custom-table",
    staticStyle: {},
    attrs: {
      "id": "table-transition",
      "fields": _vm.customedFields,
      "no-border-collapse": true,
      "items": _vm.data,
      "tbody-tr-class": "".concat(_vm.rowClass(), " table-sal"),
      "tbody-transition-props": _vm.transProps,
      "small": _vm.noFooter ? false : true,
      "responsive": "",
      "hover": "",
      "caption-top": "",
      "show-empty": "",
      "primary-key": "number",
      "striped": "",
      "bordered": ""
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-0 py-2 text-center"
        }, [_vm._v("Tidak ada data")])];
      },
      proxy: true
    }, {
      key: "bottom-row",
      fn: function fn(data) {
        return _vm._l(data.fields, function (field, i) {
          return _c('td', {
            key: i
          }, [i !== 0 ? _c('span', [_c('strong', [_vm._v(_vm._s(_vm.calculation[field.key]))])]) : _vm._e(), i === 1 ? _c('span', [_vm._v(" Jumlah Seluruh Data : " + _vm._s(_vm.pagination.totalItems) + " ")]) : _vm._e()]);
        });
      }
    }, _vm.multipleHeader ? {
      key: "thead-top",
      fn: function fn(data) {
        return [_c('b-tr', [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleHeader.startFrom
          }
        }), _vm._l(_vm.multipleHeader.content, function (header, index) {
          return _c('multiple-header', {
            key: index,
            ref: "removeJustThis",
            refInFor: true
          }, [_c('b-th', {
            class: header.align,
            attrs: {
              "variant": "primary",
              "colspan": header.span
            },
            domProps: {
              "textContent": _vm._s(header.value)
            }
          })], 1);
        }), _vm.multipleHeader.noEnd == false ? _c('multiple-header-end', {
          ref: "removeJustThisEnd"
        }, [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleHeader.endFrom
          }
        })], 1) : _vm._e()], 2)];
      }
    } : _vm.multipleTripleHeader ? {
      key: "thead-top",
      fn: function fn(data) {
        return [_c('b-tr', [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleTripleHeader.startFromFirst
          }
        }), _vm._l(_vm.multipleTripleHeader.contentFirst, function (header, index) {
          return _c('multiple-header', {
            key: index,
            ref: "removeJustThis",
            refInFor: true
          }, [_c('b-th', {
            class: header.align,
            attrs: {
              "variant": "primary",
              "colspan": header.span
            },
            domProps: {
              "textContent": _vm._s(header.value)
            }
          })], 1);
        }), _vm.multipleTripleHeader.noEndFirst == false ? _c('multiple-header-end', {
          ref: "removeJustThisEnd"
        }, [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleTripleHeader.endFromFirst
          }
        })], 1) : _vm._e()], 2), _c('b-tr', [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleTripleHeader.startFromSecond
          }
        }), _vm._l(_vm.multipleTripleHeader.contentSecond, function (header, index) {
          return _c('multiple-header', {
            key: index,
            ref: "removeJustThis",
            refInFor: true
          }, [_c('b-th', {
            class: header.align,
            attrs: {
              "variant": "primary",
              "colspan": header.span
            },
            domProps: {
              "textContent": _vm._s(header.value)
            }
          })], 1);
        }), _vm.multipleTripleHeader.noEndSecond == false ? _c('multiple-header-end', {
          ref: "removeJustThisEnd"
        }, [_c('b-th', {
          attrs: {
            "colspan": _vm.multipleTripleHeader.endFromSecond
          }
        })], 1) : _vm._e()], 2)];
      }
    } : null, {
      key: "head(select)",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          on: {
            "change": _vm.toggleSelectedAll
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(show_details)",
      fn: function fn(row) {
        return [_vm.noFooter === false ? _c('b-button', {
          attrs: {
            "variant": _vm.actionColor(row.item.form_status),
            "to": {
              name: _vm.enableViewForm,
              params: {
                id: row.item.id,
                api: _vm.apiEndpoint
              }
            },
            "size": "md"
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": row.item.form_status === 'NO PERIJINAN BELUM LENGKAP' ? 'PenToolIcon' : 'PenToolIcon',
            "size": "25"
          }
        })], 1) : _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.hover.top.left.html",
            value: _vm.formatJettyChildren(row.item.children),
            expression: "formatJettyChildren(row.item.children)",
            modifiers: {
              "hover": true,
              "top": true,
              "left": true,
              "html": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": 'primary',
            "size": "md",
            "title": "List Kapal yang dihitung by Waktu Sandar )"
          },
          on: {
            "click": function click($event) {
              return _vm.closeModalModalTableNoAPI(true, row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": 'PenToolIcon',
            "size": "25"
          }
        })], 1), _vm.detailFields ? _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "secondary",
            "size": "md"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": row.detailsShowing ? 'EyeIcon' : 'EyeOffIcon',
            "size": "25"
          }
        })], 1) : _vm._e()];
      }
    }, _vm.detailFields ? {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', {
          staticClass: "mb-0",
          attrs: {
            "body-class": "p-2"
          }
        }, [_c('b-row', _vm._l(_vm.detailFields, function (detail) {
          return _c('b-col', {
            key: detail.key,
            staticClass: "mb-1",
            attrs: {
              "md": detail.md,
              "cols": detail.cols
            }
          }, [detail.key === 'timeline' ? _c('div', [_c('b-form-group', {
            attrs: {
              "label": "⏰",
              "label-for": "⏰"
            }
          }, [_c('b-input-group', {
            staticClass: "input-group-merge"
          }, [_c('b-button', {
            staticClass: "mr-1",
            attrs: {
              "size": "md",
              "variant": "info"
            },
            on: {
              "click": row.toggleDetails
            }
          }, [_c('feather-icon', {
            staticClass: "cursor-pointer",
            attrs: {
              "icon": "GitBranchIcon"
            }
          }), _c('span', [_vm._v(" Lihat TimeLine")])], 1)], 1)], 1)], 1) : _c('b-form-group', {
            attrs: {
              "label": detail.label,
              "label-for": detail.label
            }
          }, [_c('b-input-group', {
            staticClass: "input-group-merge"
          }, [['date'].includes(detail.input.type) && _vm.$getPropByString(row.item, "".concat(detail.key)) ? _c('b-form-input', {
            attrs: {
              "value": _vm.formatDateTime(_vm.$getPropByString(row.item, "".concat(detail.key)), detail.input.format),
              "disabled": ""
            }
          }) : detail.key === 'dm_river_boat.dm_shipping_agent.shipping_name' && !row.item.dm_river_boat.dm_shipping_agent ? _c('b-form-input', {
            attrs: {
              "id": detail.label,
              "size": "lg",
              "value": "Data Kosong | Error (System)",
              "name": detail.label,
              "placeholder": detail.placeholderEmpty,
              "disabled": ""
            }
          }) : detail.key === 'dm_company_bongm.company_bongm_name' && !row.item.dm_company_bongm ? _c('b-form-input', {
            attrs: {
              "id": detail.label,
              "size": "lg",
              "value": "Data Kosong | Error (System)",
              "name": detail.label,
              "placeholder": detail.placeholderEmpty,
              "disabled": ""
            }
          }) : _c('b-form-input', {
            attrs: {
              "id": detail.label,
              "size": "lg",
              "value": _vm.$getPropByString(row.item, "".concat(detail.key)),
              "name": detail.label,
              "placeholder": detail.placeholderEmpty,
              "disabled": ""
            }
          })], 1)], 1)], 1);
        }), 1), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "XIcon"
          }
        }), _c('span', {
          staticClass: "ml-0"
        }, [_vm._v("Hide Details")])], 1)], 1)];
      }
    } : null, {
      key: "cell()",
      fn: function fn(data) {
        var _data$item, _data$item$rel_keg_pi, _data$item2, _data$item2$rel_keg_p, _data$item3, _data$item4, _data$item5, _data$item5$rel_keg_m, _data$item6, _data$item6$rel_keg_m, _data$item7, _data$item8, _data$field, _data$field$input, _data$field2, _data$field2$input, _attrs;
        return [_c('div', {
          staticClass: "field-custom"
        }, [data.field.key == 'number' ? _c('div', [_c('p', {
          staticClass: "mb-0 font-weight-bold",
          domProps: {
            "textContent": _vm._s(data.item.number)
          }
        })]) : data.field.key == 'select' && (_vm.deleteMode || _vm.exportMode) ? _c('div', [_c('b-form-checkbox', {
          model: {
            value: data.item.selected,
            callback: function callback($$v) {
              _vm.$set(data.item, "selected", $$v);
            },
            expression: "data.item.selected"
          }
        })], 1) : data.field.key === 'kegiatan' ? _c('div', [_c('strong', [data.item.kegiatan.includes('Manual', 'PipeLine') ? _c('span', {
          domProps: {
            "textContent": _vm._s(data.item.kegiatan)
          }
        }) : _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": data.item.kegiatan == 'BONGKAR' ? 'light-primary' : data.item.kegiatan == 'TAMBAT' ? 'light-danger' : 'light-dark'
          }
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(data.item.kegiatan)
          }
        })])], 1)]) : data.field.key === 'form_status' ? _c('div', [_c('h4', [_c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "pill": "",
            "variant": _vm.formStatusVariant(data.item.form_status)
          },
          on: {
            "click": function click($event) {
              return _vm.openModalRiver(data.item);
            }
          }
        }, [_vm.approvedLoading && _vm.approvedLoadingID === data.item.id ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": data.item.form_status === 'NO PERIJINAN BELUM LENGKAP' ? 'PenToolIcon' : data.item.form_status === 'KEGIATAN SELESAI' ? 'SendIcon' : data.item.form_status.includes(['SANDAR', 'KAPAL BERANGKAT']) ? 'AnchorIcon' : 'ClockIcon',
            "size": "25"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(data.item.form_status)
          }
        })], 1)], 1)]) : data.field.key === 'bongkar_no_doc' ? _c('div', [data.item.keg_bongkar === true ? _c('b-badge', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-data-preview",
            modifiers: {
              "modal-data-preview": true
            }
          }],
          attrs: {
            "pill": "",
            "variant": "info"
          },
          on: {
            "click": function click() {
              _vm.previewID = data.item.id;
              _vm.previewAPI = 'bongkar';
              _vm.previewTitle = "No Document (Bongkar) : ".concat(data.item.bongkar_no_doc || 'Harap Diisi', " , Asal Barang : ").concat(data.item.bongkar_asal_brg || 'Pengguna Tidak Mengisi');
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PackageIcon",
            "size": "55"
          }
        }), _c('span')], 1) : _vm._e(), _c('span', {
          staticClass: "mb-0 font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.bongkar_no_doc) + " ")])], 1) : data.field.key === 'muat_no_doc' ? _c('div', [data.item.keg_muat === true && !data.item.muat_no_doc ? _c('div', [data.item.keg_muat === true ? _c('b-badge', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-data-preview",
            modifiers: {
              "modal-data-preview": true
            }
          }],
          staticClass: "mr-25",
          attrs: {
            "pill": "",
            "variant": "info"
          },
          on: {
            "click": function click() {
              _vm.previewID = data.item.id;
              _vm.previewAPI = 'muat';
              _vm.previewTitle = "No Document (Muat) : ".concat(data.item.muat_no_doc || 'Harap Diisi', " , Tujuan : ").concat(data.item.muat_tujuan || 'Pengguna Tidak Mengisi');
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PackageIcon",
            "size": "55"
          }
        }), _c('span')], 1) : _vm._e(), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalUpdateFORM(data.item.id, 'No Dokumen (M)', data.item.muat_no_doc, data.field.key);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "AlertTriangleIcon"
          }
        }), _c('span', {
          staticClass: "mb-0 font-weight-bold"
        }, [_vm._v(" Harap Diisi ")])], 1)], 1) : _c('div', [data.item.keg_muat === true ? _c('b-badge', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-data-preview",
            modifiers: {
              "modal-data-preview": true
            }
          }],
          attrs: {
            "pill": "",
            "variant": "info"
          },
          on: {
            "click": function click() {
              _vm.previewID = data.item.id;
              _vm.previewAPI = 'muat';
              _vm.previewTitle = "No Document (Muat) : ".concat(data.item.muat_no_doc || 'Harap Diisi', " , Tujuan : ").concat(data.item.muat_tujuan || 'Pengguna Tidak Mengisi');
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PackageIcon",
            "size": "55"
          }
        }), _c('span')], 1) : _vm._e(), _c('span', {
          staticClass: "mb-0 font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.muat_no_doc) + " ")])], 1)]) : data.field.key === 'emergency_action' ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": _vm.formQRCodeVariant(data.item)
          },
          on: {
            "click": function click($event) {
              _vm.emergencyLoadingID === data.item.id ? null : _vm.onInOutToTrigger(data.item.id, data.item);
            }
          }
        }, [_vm.emergencyLoading && _vm.emergencyLoadingID === data.item.id ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "WatchIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(_vm.formQRStatus(data.item))
          }
        })], 1)], 1) : data.field.key === 'action_qrcode' ? _c('div', [_c('b-badge', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "pill": "",
            "variant": data.item.qrcode ? 'primary' : 'danger'
          },
          on: {
            "click": function click($event) {
              return _vm.showCustomImage(data.item.qrcode, data.item.dm_personal.full_name, data.item.schedule_in);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("QRCode")])], 1)], 1) : ['pip_signatured', 'mh_signatured'].includes(data.field.key) ? _c('div', [!data.item.onEdit ? _c('span', [_c('strong', [_vm._v(_vm._s(data.item.user.full_name))])]) : _c('span', [_vm._v(" otomatis terisi ")])]) : data.field.key === 'pip_action' ? _c('div', [!data.item.onEdit ? _c('b-badge', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "pill": "",
            "variant": ((_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$rel_keg_pi = _data$item.rel_keg_pipelines) === null || _data$item$rel_keg_pi === void 0 ? void 0 : _data$item$rel_keg_pi.length) == 0 ? 'danger' : 'success'
          },
          on: {
            "click": function click($event) {
              return _vm.closeModalFormAndTableModalPL(true, data.item.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "CpuIcon"
          }
        }), _c('span', [_vm._v("Realisasikan "), (_data$item2 = data.item) !== null && _data$item2 !== void 0 && (_data$item2$rel_keg_p = _data$item2.rel_keg_pipelines) !== null && _data$item2$rel_keg_p !== void 0 && _data$item2$rel_keg_p.length ? _c('span', [_vm._v("( " + _vm._s(_vm.checkRealisasi((_data$item3 = data.item) === null || _data$item3 === void 0 ? void 0 : _data$item3.rel_keg_pipelines)) + " " + _vm._s((_data$item4 = data.item) === null || _data$item4 === void 0 ? void 0 : _data$item4.pip_satuan) + " )")]) : _vm._e()])], 1) : _c('span', [_vm._v(" jika ada kolom yang kosong, maka 1 kolom tersebut tidak akan tersimpan. ")])], 1) : data.field.key === 'mh_action' ? _c('div', [!data.item.onEdit ? _c('b-badge', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "pill": "",
            "variant": ((_data$item5 = data.item) === null || _data$item5 === void 0 ? void 0 : (_data$item5$rel_keg_m = _data$item5.rel_keg_mhs) === null || _data$item5$rel_keg_m === void 0 ? void 0 : _data$item5$rel_keg_m.length) == 0 ? 'danger' : 'success'
          },
          on: {
            "click": function click($event) {
              return _vm.closeModalFormAndTableModalMH(true, data.item.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "CpuIcon"
          }
        }), _c('span', [_vm._v("Realisasikan "), (_data$item6 = data.item) !== null && _data$item6 !== void 0 && (_data$item6$rel_keg_m = _data$item6.rel_keg_mhs) !== null && _data$item6$rel_keg_m !== void 0 && _data$item6$rel_keg_m.length ? _c('span', [_vm._v("( " + _vm._s(_vm.checkRealisasiMH((_data$item7 = data.item) === null || _data$item7 === void 0 ? void 0 : _data$item7.rel_keg_mhs)) + " " + _vm._s((_data$item8 = data.item) === null || _data$item8 === void 0 ? void 0 : _data$item8.mh_satuan) + " )")]) : _vm._e()])], 1) : _c('span', [_vm._v(" jika ada kolom yang kosong, maka 1 kolom tersebut tidak akan tersimpan. ")])], 1) : data.field.key === 'qrcode_status' ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": _vm.qrcodeStatusVariant(data.item.qrcode_status)
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "WatchIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(data.item.qrcode_status)
          }
        })], 1)], 1) : data.field.key === 'action_form' ? _c('div', [_c('b-dropdown', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "flat-primary",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [!_vm.$can('manage', 'qrcode') ? _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'monitoring-jalur-darat-form-type-3-wizard',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "TruckIcon"
          }
        }), _c('span', {
          staticClass: "text-danger align-middle ml-50"
        }, [_vm._v("Lakukan Revisi")])], 1) : _vm._e(), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-data-history",
            modifiers: {
              "modal-data-history": true
            }
          }],
          on: {
            "click": function click() {
              return _vm.historyID = data.item.id;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "text-warning",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', {
          staticClass: "text-warning align-middle ml-50"
        }, [_vm._v("History Data")])], 1), !_vm.$can('manage', 'qrcode') ? _c('b-dropdown-item', [_c('feather-icon', {
          staticClass: "text-info",
          attrs: {
            "icon": "PackageIcon"
          }
        }), _c('span', {
          staticClass: "text-info align-middle ml-50"
        }, [_vm._v("Buat Operasional")])], 1) : _vm._e()], 1)], 1) : data.field.key === 'pemilik' || data.field.key === 'group.name' ? _c('div', [_c('b-badge', {
          attrs: {
            "href": "#",
            "variant": data.unformatted === 'TEM' ? 'info' : 'primary'
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "LinkIcon"
          }
        }), _c('span', {
          domProps: {
            "textContent": _vm._s(data.unformatted)
          }
        })], 1)], 1) : ((_data$field = data.field) === null || _data$field === void 0 ? void 0 : (_data$field$input = _data$field.input) === null || _data$field$input === void 0 ? void 0 : _data$field$input.type) === 'select-multiple' && !data.item.onEdit ? _c('div', _vm._l(data.unformatted, function (item, index) {
          return _c('span', {
            key: index
          }, [_c('b-badge', {
            directives: [{
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(113, 102, 240, 0.15)',
              expression: "'rgba(113, 102, 240, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "mb-1 mr-1",
            attrs: {
              "pill": "",
              "variant": 'primary'
            }
          }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
        }), 0) : ((_data$field2 = data.field) === null || _data$field2 === void 0 ? void 0 : (_data$field2$input = _data$field2.input) === null || _data$field2$input === void 0 ? void 0 : _data$field2$input.type) === 'custom-hulu' && !data.item.onEdit ? _c('div', [_c('b-row', _vm._l(data.unformatted, function (item, index) {
          return _c('b-col', {
            key: index,
            attrs: {
              "cols": "6"
            }
          }, [_c('b-badge', {
            directives: [{
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(113, 102, 240, 0.15)',
              expression: "'rgba(113, 102, 240, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "mb-1 d-block",
            attrs: {
              "pill": "",
              "variant": 'light-primary'
            }
          }, [_vm._v(" " + _vm._s(item) + " " + _vm._s(_vm.pekerja[index]) + " ")])], 1);
        }), 1)], 1) : _c('div', {
          staticClass: "custom-field",
          style: {
            cursor: _vm.isColumnEditable(data) ? 'pointer' : 'text'
          },
          on: {
            "dblclick": function dblclick($event) {
              _vm.forceDisableCrud == true ? '' : _vm.specifyCrud ? _vm.user_id !== data.item.user.id ? _vm.alertNoPermission() : _vm.onSelectData(data) : _vm.onSelectData(data);
            }
          }
        }, [data.item.onEdit && _vm.isColumnEditable(data) ? _c('div', [_vm.$slots[data.field.columnKey || data.field.key] || _vm.$scopedSlots[data.field.columnKey || data.field.key] ? _vm._t(data.field.key, null, {
          "column": {
            data: data,
            onUpdate: _vm.onUpdate,
            onDelete: _vm.onDelete
          }
        }) : _vm._e(), _c('div', [data.field.input != undefined ? _c('div', [['select'].includes(data.field.input.type) && 'columnKey' in data.field ? _c('v-select', {
          staticClass: "custom-yo-select",
          attrs: {
            "disabled": data.field.input.disabled,
            "state": data.field.columnKey === 'dm_land_vehicle_id' && data.item[data.field.columnKey || data.field.key] === 0 ? false : data.field.input.options && data.field.input.boolean ? true : true,
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.selectModal.options
          },
          on: {
            "keyup": [function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              if (!$event.shiftKey) { return null; }
              if ($event.ctrlKey || $event.altKey || $event.metaKey) { return null; }
              return _vm.showSelectModal(data);
            }, function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              if (!$event.ctrlKey) { return null; }
              if ($event.shiftKey || $event.altKey || $event.metaKey) { return null; }
              return _vm.showSelectModal(data);
            }],
            "input": function input($event) {
              return _vm.showSelectModal(data);
            },
            "search:focus": function searchFocus($event) {
              return _vm.showSelectModal(data);
            },
            "search:blur": function searchBlur($event) {
              return _vm.showSelectModal(data);
            }
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(option) {
              return [_vm._v(" " + _vm._s(option.label || option.value) + " ")];
            }
          }, {
            key: "option",
            fn: function fn(option) {
              return [_c('strong', [_c('span', {
                staticClass: "outside"
              }, [_vm._v(_vm._s(option.label))])])];
            }
          }], null, true),
          model: {
            value: data.item[data.field.key.toString().split('.')[0]][data.field.key.toString().split('.')[1]],
            callback: function callback($$v) {
              _vm.$set(data.item[data.field.key.toString().split('.')[0]], data.field.key.toString().split('.')[1], $$v);
            },
            expression: "\n                      data.item[data.field.key.toString().split('.')[0]][\n                        data.field.key.toString().split('.')[1]\n                      ]\n                    "
          }
        }) : ['select'].includes(data.field.input.type) ? _c('v-select', {
          staticClass: "custom-yo-select",
          attrs: {
            "disabled": data.field.input.disabled,
            "state": data.field.columnKey === 'dm_land_vehicle_id' && data.item[data.field.columnKey || data.field.key] === 0 ? false : data.field.input.options && data.field.input.boolean ? true : true,
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.selectModal.options
          },
          on: {
            "keyup": [function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              if (!$event.shiftKey) { return null; }
              if ($event.ctrlKey || $event.altKey || $event.metaKey) { return null; }
              return _vm.showSelectModal(data);
            }, function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              if (!$event.ctrlKey) { return null; }
              if ($event.shiftKey || $event.altKey || $event.metaKey) { return null; }
              return _vm.showSelectModal(data);
            }],
            "input": function input($event) {
              return _vm.showSelectModal(data);
            },
            "search:focus": function searchFocus($event) {
              return _vm.showSelectModal(data);
            },
            "search:blur": function searchBlur($event) {
              return _vm.showSelectModal(data);
            }
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(option) {
              return [_vm._v(" " + _vm._s(option.label || option.value) + " ")];
            }
          }, {
            key: "option",
            fn: function fn(option) {
              return [_c('strong', [_c('span', {
                staticClass: "outside"
              }, [_vm._v(_vm._s(option.label))])])];
            }
          }], null, true),
          model: {
            value: data.item[data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.key, $$v);
            },
            expression: "data.item[data.field.key]"
          }
        }) : ['select-multiple'].includes(data.field.input.type) ? _c('v-select', {
          staticClass: "custom-yo-select",
          attrs: {
            "multiple": "",
            "disabled": data.field.input.disabled,
            "state": data.field.columnKey === 'dm_land_vehicle_id' && data.item[data.field.columnKey || data.field.key] === 0 ? false : data.field.input.options && data.field.input.boolean ? true : true,
            "reduce": function reduce(item) {
              return item.value;
            },
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.selectModal.options
          },
          on: {
            "keyup": [function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              if (!$event.shiftKey) { return null; }
              if ($event.ctrlKey || $event.altKey || $event.metaKey) { return null; }
              return _vm.showSelectModal(data);
            }, function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              if (!$event.ctrlKey) { return null; }
              if ($event.shiftKey || $event.altKey || $event.metaKey) { return null; }
              return _vm.showSelectModal(data);
            }],
            "input": function input($event) {
              return _vm.showSelectModal(data);
            },
            "search:focus": function searchFocus($event) {
              return _vm.showSelectModal(data);
            },
            "search:blur": function searchBlur($event) {
              return _vm.showSelectModal(data);
            }
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(option) {
              return [_vm._v(" " + _vm._s(option.label || option.value) + " ")];
            }
          }, {
            key: "option",
            fn: function fn(option) {
              return [_c('strong', [_c('span', {
                staticClass: "outside"
              }, [_vm._v(_vm._s(option.label))])])];
            }
          }], null, true),
          model: {
            value: data.item[data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.key, $$v);
            },
            expression: "data.item[data.field.key]"
          }
        }) : ['time'].includes(data.field.input.type) ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "disabled": data.field.input.disabled,
            "value": _vm.beforeSentDate(data),
            "placeholder": data.field.placeholder,
            "state": data.field.columnKey === 'dm_land_vehicle_id' && data.item[data.field.columnKey || data.field.key] === 0 ? false : data.field.input.options && data.field.input.boolean ? true : true,
            "allowinput": data.field.input.disabled ? 'allowinput' : '',
            "static": "true",
            "config": _vm.dpconfig
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "data.item[data.field.columnKey || data.field.key]"
          }
        }) : data.field.input.type == 'number' ? _c('b-form-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "number",
            "disabled": data.field.input.disabled,
            "placeholder": "".concat(data.item[data.field.columnKey || data.field.key] || data.field.placeholder),
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "data.item[data.field.columnKey || data.field.key]"
          }
        }) : data.field.input.type == 'tag' ? _c('b-form-tags', {
          attrs: {
            "input-id": "tag-".concat(data.uuid),
            "input-attrs": {
              'aria-describedby': 'tags-remove-on-delete-help'
            },
            "separator": ",",
            "placeholder": "1. DATA A, 2. DATA B",
            "remove-on-delete": "",
            "no-add-on-enter": ""
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "data.item[data.field.columnKey || data.field.key]"
          }
        }) : data.field.input.type == 'number-cleave' ? _c('b-input-group', {
          attrs: {
            "append": data.field.input.append
          }
        }, [_c('cleave', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          staticClass: "form-control",
          attrs: (_attrs = {
            "required": "",
            "disabled": data.field.input.disabled,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true,
            "placeholder": "".concat(data.field.placeholder),
            "raw": true,
            "options": {
              numeral: true,
              numeralDecimalMark: ',',
              delimiter: '.'
            }
          }, _attrs["placeholder"] = "1.000 (dot automaticaly)", _attrs),
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                        data.item[data.field.columnKey || data.field.key]\n                      "
          }
        })], 1) : data.field.input.type == 'number-append' ? _c('b-input-group', {
          attrs: {
            "append": data.field.input.append
          }
        }, [_c('b-form-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "number",
            "required": "",
            "disabled": data.field.input.disabled,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true,
            "placeholder": "".concat(data.field.placeholder)
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                        data.item[data.field.columnKey || data.field.key]\n                      "
          }
        })], 1) : data.field.input.type == 'currency' ? _c('b-input-group', {
          attrs: {
            "append": data.field.input.append
          }
        }, [_c('currency-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "disabled": data.field.input.disabled,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true,
            "placeholder": "".concat(data.item[data.field.columnKey || data.field.key]),
            "options": {
              currency: 'IDR',
              locale: 'id-ID',
              currencyDisplay: 'hidden',
              autoDecimalDigits: false,
              precision: 2
            }
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "\n                        data.item[data.field.columnKey || data.field.key]\n                      "
          }
        })], 1) : data.field.input.type == 'input-noarea' ? _c('b-form-input', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "text",
            "disabled": data.field.input.disabled,
            "placeholder": data.field.placeholder,
            "value": data.unformatted,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "data.item[data.field.columnKey || data.field.key]"
          }
        }) : data.field.input.type == 'custom-hulu' ? _c('div', [_c('b-input-group', {
          attrs: {
            "append": "SUPERVISOR"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "0",
            "type": "number"
          },
          model: {
            value: data.item[data.field.splitModel.one],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.splitModel.one, $$v);
            },
            expression: "data.item[data.field.splitModel.one]"
          }
        })], 1), _c('b-input-group', {
          attrs: {
            "append": "RIGGER"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "0",
            "type": "number"
          },
          model: {
            value: data.item[data.field.splitModel.two],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.splitModel.two, $$v);
            },
            expression: "data.item[data.field.splitModel.two]"
          }
        })], 1), _c('b-input-group', {
          attrs: {
            "append": "TALLYMAN"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "0",
            "type": "number"
          },
          model: {
            value: data.item[data.field.splitModel.three],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.splitModel.three, $$v);
            },
            expression: "data.item[data.field.splitModel.three]"
          }
        })], 1), _c('b-input-group', {
          attrs: {
            "append": "HSE"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "0",
            "type": "number"
          },
          model: {
            value: data.item[data.field.splitModel.four],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.splitModel.four, $$v);
            },
            expression: "data.item[data.field.splitModel.four]"
          }
        })], 1), _c('b-input-group', {
          attrs: {
            "append": "JETTYMAN"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "0",
            "type": "number"
          },
          model: {
            value: data.item[data.field.splitModel.five],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.splitModel.five, $$v);
            },
            expression: "data.item[data.field.splitModel.five]"
          }
        })], 1), _c('b-input-group', {
          attrs: {
            "append": "SECURITY"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "0",
            "type": "number"
          },
          model: {
            value: data.item[data.field.splitModel.six],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.splitModel.six, $$v);
            },
            expression: "data.item[data.field.splitModel.six]"
          }
        })], 1)], 1) : _c('b-form-textarea', {
          ref: "".concat(data.field.key, "-").concat(data.item.uuid),
          attrs: {
            "type": "text",
            "disabled": data.field.input.disabled,
            "placeholder": data.field.placeholder,
            "value": data.unformatted,
            "state": data.item[data.field.columnKey || data.field.key] === '' ? false : true
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
              return function (event) {
                return _vm.onDelete(data.item.id, true, event);
              }.apply(null, arguments);
            }
          },
          model: {
            value: data.item[data.field.columnKey || data.field.key],
            callback: function callback($$v) {
              _vm.$set(data.item, data.field.columnKey || data.field.key, $$v);
            },
            expression: "data.item[data.field.columnKey || data.field.key]"
          }
        })], 1) : _vm._e()])], 2) : _c('div', [data.unformatted !== '' ? _c('div', ['input' in data.field && 'boolean' in data.field.input && data.field.input.boolean === true ? _c('div', [_c('span', {
          domProps: {
            "textContent": _vm._s(data.unformatted == '1' ? '✓' : '✕')
          }
        })]) : 'type' in data.field.input && data.field.input.type === 'date' ? _c('div', [_c('p', {
          staticClass: "mb-0 font-weight-bold",
          domProps: {
            "textContent": _vm._s(_vm.tableDateFormatter(data.unformatted, data.field.input.format))
          }
        })]) : 'type' in data.field.input && data.field.input.type === 'time' ? _c('div', [_c('p', {
          staticClass: "mb-0 font-weight-bold"
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(_vm.tableTimeFormatter(data.unformatted, data.field.input.format))
          }
        }), _c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": _vm.tableTimeFormatter(data.unformatted, data.field.input.format).toString().includes('PM') ? 'MoonIcon' : 'SunIcon'
          }
        })], 1)]) : data.field.input.type === 'tag' ? _c('div', [_c('b-form-tags', {
          attrs: {
            "input-id": "tag-".concat(data.uuid),
            "input-attrs": {
              'aria-describedby': 'tags-remove-on-delete-help'
            },
            "separator": ",",
            "placeholder": "",
            "disabled": "true",
            "remove-on-delete": "",
            "no-add-on-enter": ""
          },
          model: {
            value: data.unformatted,
            callback: function callback($$v) {
              _vm.$set(data, "unformatted", $$v);
            },
            expression: "data.unformatted"
          }
        })], 1) : ['mh_pemilik_brg', 'pip_pemilik_brg'].includes(data.field.key) ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": ['DOWELL'].includes(data.item.mh_pemilik_brg || data.item.pip_pemilik_brg) ? 'light-danger' : 'light-primary'
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PackageIcon",
            "size": "55"
          }
        }), _c('span', {
          staticClass: "mb-0 font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.mh_pemilik_brg || data.item.pip_pemilik_brg) + " ")])], 1)], 1) : ['mh_type_rl'].includes(data.field.key) ? _c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": ['STEVEDORING'].includes(data.item.mh_type_rl) ? 'warning' : 'primary'
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PackageIcon",
            "size": "55"
          }
        }), _c('span', {
          staticClass: "mb-0 font-weight-bold ml-1"
        }, [_c('strong', [_vm._v(_vm._s(data.item.mh_type_rl))])])], 1)], 1) : data.field.input.type === 'number-cleave' ? _c('div', [_c('span', {
          staticClass: "mb-0 font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.formatNumber(Number(data.unformatted))) + " ")])]) : _c('div', [_c('span', {
          staticClass: "mb-0 font-weight-bold",
          domProps: {
            "textContent": _vm._s(data.unformatted)
          }
        })])]) : _c('span', {
          domProps: {
            "textContent": _vm._s('-')
          }
        })])])])];
      }
    }], null, true)
  }), _vm.noFooter === false ? _c('b-card-footer', [_c('b-row', {
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "mb-2 mb-sm-0",
    attrs: {
      "sm": "12",
      "md": "2"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.pagination.amountOptions
    },
    model: {
      value: _vm.pagination.selectedAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "selectedAmount", $$v);
      },
      expression: "pagination.selectedAmount"
    }
  })], 1), _c('b-col', [_c('strong', [_vm._v("TIPS:")]), _vm._v(" Click "), _c('code', [_vm._v("Tab")]), _vm._v(" untuk pindah / "), _c('i', [_vm._v("next")]), _vm._v(" posisi pengisian. ")]), _c('b-col', {
    staticClass: "d-flex justify-content-center justify-content-sm-end",
    attrs: {
      "sm": "12",
      "md": "2"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0",
    attrs: {
      "total-rows": _vm.pagination.totalItems,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item",
      "per-page": _vm.pagination.selectedAmount
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _vm.refSpecify === 'TBBMTable' ? _c('SidebarAddNewTBBM', {
    attrs: {
      "is-add-new-sidebar-active": _vm.isAddNewSidebarActive
    },
    on: {
      "update:isAddNewSidebarActive": function updateIsAddNewSidebarActive($event) {
        _vm.isAddNewSidebarActive = $event;
      },
      "update:is-add-new-sidebar-active": function updateIsAddNewSidebarActive($event) {
        _vm.isAddNewSidebarActive = $event;
      },
      "submit-data": _vm.submitData
    }
  }) : _vm._e(), _vm.refSpecify === 'HuluMigasTable' ? _c('SidebarAddNewHM', {
    attrs: {
      "is-add-new-sidebar-active": _vm.isAddNewSidebarActive
    },
    on: {
      "update:isAddNewSidebarActive": function updateIsAddNewSidebarActive($event) {
        _vm.isAddNewSidebarActive = $event;
      },
      "update:is-add-new-sidebar-active": function updateIsAddNewSidebarActive($event) {
        _vm.isAddNewSidebarActive = $event;
      },
      "submit-data": _vm.submitData
    }
  }) : _vm._e(), _vm.refSpecify === 'SpeedBoatTable' ? _c('SidebarAddNewSB', {
    attrs: {
      "is-add-new-sidebar-active": _vm.isAddNewSidebarActive
    },
    on: {
      "update:isAddNewSidebarActive": function updateIsAddNewSidebarActive($event) {
        _vm.isAddNewSidebarActive = $event;
      },
      "update:is-add-new-sidebar-active": function updateIsAddNewSidebarActive($event) {
        _vm.isAddNewSidebarActive = $event;
      },
      "submit-data": _vm.submitData
    }
  }) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": _vm.refSpecify + '_select-modal',
      "title": "Pilihan",
      "body-class": "py-2",
      "hide-footer": "",
      "centered": "",
      "hide-backdrop": "",
      "no-fade": "",
      "content-class": "shadow",
      "no-enforce-focus": true
    },
    on: {
      "show": _vm.selectModalOpen
    }
  }, [_c('label', {
    staticClass: "mb-1",
    attrs: {
      "for": "pilihan"
    }
  }, [_vm._v(_vm._s(_vm.selectModal.label))]), _c('b-form-group', [_vm.selectModal.type == 'select' ? _c('v-select', {
    ref: "select",
    attrs: {
      "id": "pilihan",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.selectModal.options,
      "reduce": _vm.selectModal.reduce
    },
    on: {
      "search": function search(keyword, loading) {
        return _vm.fetchOptions(keyword, loading);
      }
    },
    model: {
      value: _vm.selectModal.value,
      callback: function callback($$v) {
        _vm.$set(_vm.selectModal, "value", $$v);
      },
      expression: "selectModal.value"
    }
  }, [_c('span', {
    attrs: {
      "slot": "no-options"
    },
    slot: "no-options"
  }, [_vm._v(_vm._s(this.$t('no_data')))])]) : _vm.selectModal.type == 'date' ? _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "static": "true",
      "config": _vm.dpconfig
    },
    model: {
      value: _vm.selectModal.value,
      callback: function callback($$v) {
        _vm.$set(_vm.selectModal, "value", $$v);
      },
      expression: "selectModal.value"
    }
  }) : _vm._e()], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "delete",
      "title": "Hapus",
      "cancel-variant": "secondary",
      "cancel-title": "Tidak",
      "ok-title": "Iya",
      "ok-variant": "danger",
      "body-class": "py-2",
      "footer-class": "d-flex justify-content-center",
      "centered": ""
    },
    on: {
      "ok": _vm.onDeleteToSelected
    }
  }, [_c('b-card-text', {
    staticClass: "text-center"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Hapus data ?")]), _c('br'), _vm._l(_vm.data.filter(function (data) {
    return data.selected;
  }), function (item) {
    return _c('div', {
      key: item.number
    }, [_vm._v(" # " + _vm._s(item.number) + ", ")]);
  })], 2)], 1), _c('b-modal', {
    attrs: {
      "id": _vm.refSpecify + '_update_form',
      "title": "Melengkapi Perijinan",
      "ok-title": "Update",
      "ok-variant": "success",
      "cancel-variant": "outline-secondary",
      "size": "lg"
    },
    on: {
      "ok": _vm.submitModalUpdateFORM
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": _vm.updateFormModal.label,
      "label-for": _vm.updateFormModal.label
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": _vm.updateFormModal.label,
      "placeholder": _vm.updateFormModal.label
    },
    model: {
      value: _vm.updateFormModal.value,
      callback: function callback($$v) {
        _vm.$set(_vm.updateFormModal, "value", $$v);
      },
      expression: "updateFormModal.value"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.updateFormModal.label,
      "label-for": _vm.updateFormModal.label
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": _vm.updateFormModal.label,
      "placeholder": _vm.updateFormModal.label
    },
    model: {
      value: _vm.updateFormModal.value,
      callback: function callback($$v) {
        _vm.$set(_vm.updateFormModal, "value", $$v);
      },
      expression: "updateFormModal.value"
    }
  })], 1)], 1)], 1), _vm.historyID ? _c('DataHistoryM', {
    ref: "modalhistory",
    attrs: {
      "id": "modal-data-history",
      "read-history-i-d": _vm.historyID,
      "ref-specify": _vm.refSpecify + '_history',
      "api-endpoint": _vm.apiEndpoint,
      "fields": _vm.fields.filter(function (e) {
        return e.key !== 'action_form' && e.key !== 'emergency_action';
      }),
      "disable-edit": _vm.disableEdit,
      "force-disable-crud": true
    }
  }) : _vm._e(), _vm.previewID ? _c('PreviewModal', {
    ref: "previewModal",
    attrs: {
      "id": "modal-data-preview",
      "title-tbl": _vm.previewTitle,
      "read-history-i-d": _vm.previewID,
      "ref-specify": _vm.refSpecify + '_preview',
      "api-endpoint": _vm.previewAPI,
      "fields": _vm.previewField,
      "disable-edit": _vm.disableEdit,
      "force-disable-crud": true
    }
  }) : _vm._e(), _c('FormAndTableModal', {
    key: "FormAndTableModalPL",
    attrs: {
      "uri": "/fhm_keg_pipeline/".concat(_vm.fhmID, "/").concat(_vm.fhm_keg_pipeline),
      "uriSecond": "/rel_keg_pipeline/".concat(_vm.fhm_keg_pipeline),
      "form-chain-hm-i-d": _vm.formChainHmID,
      "title": "Form Realisasi Kegiatan (PIPELINE)",
      "forceDisableCrud": _vm.forceDisableCrud || _vm.$can('Hulu Migas', ''),
      "is-modal-active": _vm.isFormAndTableModalPL
    },
    on: {
      "update:isModalActive": function updateIsModalActive($event) {
        _vm.isFormAndTableModalPL = $event;
      },
      "update:is-modal-active": function updateIsModalActive($event) {
        _vm.isFormAndTableModalPL = $event;
      },
      "closeModal": _vm.closeModalFormAndTableModalPL
    }
  }), _c('ModalTableNoAPI', {
    key: "ModalTableNoAPI",
    attrs: {
      "title": "Detail Boat Analytic",
      "item": (_vm$boat_analytic_chi = _vm.boat_analytic_children) !== null && _vm$boat_analytic_chi !== void 0 ? _vm$boat_analytic_chi : [],
      "is-modal-active": _vm.isModalTableNoAPI
    },
    on: {
      "update:isModalActive": function updateIsModalActive($event) {
        _vm.isModalTableNoAPI = $event;
      },
      "update:is-modal-active": function updateIsModalActive($event) {
        _vm.isModalTableNoAPI = $event;
      },
      "closeModal": _vm.closeModalModalTableNoAPI
    }
  }), _c('FormAndTableModalManualHandling', {
    key: "FormAndTableModalMH",
    attrs: {
      "uri": "/fhm_keg_manh/".concat(_vm.fhmID, "/").concat(_vm.fhm_keg_mh),
      "uriSecond": "/rel_keg_mh/".concat(_vm.fhm_keg_mh),
      "form-chain-hm-i-d": _vm.formChainHmID,
      "forceDisableCrud": _vm.forceDisableCrud || _vm.$can('Hulu Migas', ''),
      "title": "Form Realisasi Kegiatan (Manual Handling)",
      "is-modal-active": _vm.isFormAndTableModalMH
    },
    on: {
      "update:isModalActive": function updateIsModalActive($event) {
        _vm.isFormAndTableModalMH = $event;
      },
      "update:is-modal-active": function updateIsModalActive($event) {
        _vm.isFormAndTableModalMH = $event;
      },
      "closeModal": _vm.closeModalFormAndTableModalMH
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }