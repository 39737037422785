var render = function () {
  var _vm$item, _vm$item2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-sal",
      "cancel-variant": "outline-secondary",
      "cancel-title": "Close",
      "centered": "",
      "size": "lg",
      "hide-footer": true,
      "title": "".concat(_vm.title, " - Jetty SAL")
    },
    model: {
      value: _vm.modalShow,
      callback: function callback($$v) {
        _vm.modalShow = $$v;
      },
      expression: "modalShow"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('div', [_vm._v(" Jumlah Sandar : " + _vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.total_items) + "x "), _c('br'), _c('strong', [_vm._v("Nama Kapal: " + _vm._s((_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.label))])])])]), _c('vue-good-table', {
    staticClass: "yo-good-table",
    attrs: {
      "columns": _vm.columns,
      "rows": _vm.getRows,
      "search-options": {
        enabled: true
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'fullName' ? _c('div', {
          staticClass: "text-nowrap"
        }) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }