var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('analytics-congratulation', {
    attrs: {
      "data": _vm.getAnalytic.congratulations
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_vm.getAnalytic.tbbm_gained ? _c('statistic-card-with-area-chart', {
    key: _vm.componentKey.tbbm,
    attrs: {
      "icon": "AnchorIcon",
      "statistic": _vm.kFormatter(_vm.getAnalytic.tbbm_gained.analytics_data.total_tambat),
      "statistic-title": "Trafic Jetty SAL",
      "chart-data": _vm.getAnalytic.tbbm_gained.series
    }
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_vm.getAnalytic.hulu_migas_gained ? _c('statistic-card-with-area-chart', {
    key: _vm.componentKey.hm,
    attrs: {
      "icon": "AnchorIcon",
      "color": "warning",
      "statistic": _vm.kFormatter(_vm.getAnalytic.hulu_migas_gained.analytics_data.total_tambat),
      "statistic-title": "Trafic Jetty SAL",
      "chart-data": _vm.getAnalytic.hulu_migas_gained.series
    }
  }) : _vm._e()], 1)], 1), _c('b-card', {
    attrs: {
      "body-class": "p-0 custom-card"
    }
  }, [_c('b-row', {
    staticClass: "yo-row mb-1 p-2",
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Choose Filter Month & Year",
      "label-for": "choose-month-year"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "choose-month-year",
      "static": "true",
      "config": _vm.dpconfig
    },
    model: {
      value: _vm.monthyear,
      callback: function callback($$v) {
        _vm.monthyear = $$v;
      },
      expression: "monthyear"
    }
  })], 1)], 1), _vm.isLoading ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_c('i', [_c('strong', [_vm._v(" \"Selamat datang di jetty SAL! Sementara data analitik kita dimuat dengan kecermatan oleh AI, bersiaplah untuk menemukan wawasan yang mendalam sebentar lagi.\" ")])])]), _c('hr'), _c('b-progress', {
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "variant": "primary",
      "value": 100,
      "max": "100"
    }
  })], 1) : _vm._e()], 1)], 1), _c('b-card', {
    attrs: {
      "body-class": "p-0 custom-card"
    }
  }, [_c('div', {
    staticClass: "p-2"
  }, [_c('GraphLineTambat', {
    key: _vm.componentKey.statistic,
    attrs: {
      "analytic": _vm.getAnalytic
    }
  })], 1)]), _c('CompLightTable', {
    key: _vm.componentKey.table,
    ref: "TableCountRiver",
    attrs: {
      "ref-specify": "TableCountRiverSpec",
      "api-endpoint": _vm.apiEndpoint,
      "sub-title-tbl": "",
      "fields": _vm.fields,
      "new-data-template": _vm.newDataTemplate,
      "resolve-fetch-response": _vm.resolveFetchResponse,
      "resolve-fetch-url": _vm.resolveFetchUrl,
      "bottom-label": this.$t('KeteranganTable') + '~' + this.$t('Form Land Vehicle') + '~',
      "bottom-icon": "GitMergeIcon",
      "info-page-ability": "Manage ( All & SAL ) only",
      "placeholder-search": "Kegiatan Name",
      "disableEdit": true,
      "force-disable-crud": true,
      "no-footer": true
    },
    on: {
      "render-component": _vm.renderComponent
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }