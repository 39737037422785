<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Total Kapal Tambat / Sandar
        <hr>
      </b-card-title>
      <feather-icon
        icon="SettingsIcon"
        size="18"
        class="text-muted cursor-pointer"
      />
    </b-card-header>

    <b-card-body class="pb-0">
      <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50">
            This Month is
          </b-card-text>
          <h3 class="font-weight-bolder">
            <span class="text-primary">{{ analytic.totals }}x</span>
            <!-- <sup class="font-medium-1">x </sup> -->
          </h3>
        </div>
        <div class="mr-2">
          <b-card-text class="mb-50">
            TBBM
          </b-card-text>
          <h3 class="font-weight-bolder">
            <!-- <sup class="font-medium-1">$ </sup> -->
            <span>{{ analytic.tbbm_gained.analytics_data.total_tambat }}x</span>
          </h3>
        </div>
        <div>
          <b-card-text class="mb-50">
            Hulu Migas
          </b-card-text>
          <h3 class="font-weight-bolder">
            <!-- <sup class="font-medium-1">$ </sup> -->
            <span>{{ analytic.hulu_migas_gained.analytics_data.total_tambat }}x</span>
          </h3>
        </div>
      </div>

      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="240"
        :options="sal_apex.chartOptions"
        :series="sal_apex.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: {
    analytic: {
      type: [Object, Array],
      required: false,
      default: null
    },
  },
  data() {
    return {
      sal_apex: {
        series: [
          ...this.analytic.hulu_migas_gained.series,
          ...this.analytic.tbbm_gained.series,
          // {
          //   name: 'TBBM',
          //   data: [
          //     450100, 470200, 448300, 475400, 455500, 480600, 465700, 486800, 500900, 1001000,
          //     4501100, 4701200, 4481300, 4751400, 4551500, 4801600, 4651700, 4861800, 5001900, 1002000,
          //     4502100, 4702200, 4482300, 4752400, 4552500, 4802600, 4652700, 4862800, 5002900, 1003000,
          //   ],
          // },
          // {
          //   name: 'Hulu Migas',
          //   data: [
          //     461000, 482000, 453000, 464000, 4450000, 4660000, 4570000, 4780, 2090, 30100,
          //     461100, 481200, 451300, 461400, 4415000, 4616000, 4517000, 47180, 20190, 30200,
          //     462100, 482200, 452300, 462400, 4425000, 4626000, 4527000, 47280, 20290, 30300,
          //   ],
          // },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: true },
            zoom: { enabled: true },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: [$themeColors.primary, '#f4b16f'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: true,
              gradientToColors: [$themeColors.primary, '#f4b16f'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: true,
            },
            categories: this.analytic.days_of_month,
            // categories: [
            //   '1-03-24', '2-03-24', '3-03-24', '4-03-24', '5-03-24', '6-03-24', '7-03-24', '8-03-24', '9-03-24', '10-03-24',
            //   '11-03-24', '12-03-24', '13-03-24', '14-03-24', '15-03-24', '16-03-24', '17-03-24', '18-03-24', '19-03-24', '20-03-24',
            //   '21-03-24', '22-03-24', '23-03-24', '24-03-24', '25-03-24', '26-03-24', '27-03-24', '28-03-24', '29-03-24', '30-03-24',
            // ],
            axisBorder: {
              show: true,
            },
            tickPlacement: 'on',
            boundaryGap: true,
            triggerEvent: true,
            axisLabel: { rotate: 50, interval: 0 }
          },
          yaxis: {
            tickAmount: 5,
            axisBorder: {
              show: true,
            },
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}x` : val
              },
            },
          },
          grid: {
            show: true,
            borderColor: '#b9b9c2',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          legend: {
            show: true,
          },
          tooltip: {
            x: { show: true },
          },
        },
      },
    }
  },
  created() {
    // this.$http.get('/card/card-analytics/revenue').then(res => { this.revenue = res.data })
  },
}
</script>
