<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <analytics-congratulation :data="getAnalytic.congratulations" />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="getAnalytic.tbbm_gained"
          :key="componentKey.tbbm"
          icon="AnchorIcon"
          :statistic="kFormatter(getAnalytic.tbbm_gained.analytics_data.total_tambat)"
          statistic-title="Trafic Jetty SAL"
          :chart-data="getAnalytic.tbbm_gained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="getAnalytic.hulu_migas_gained"
          :key="componentKey.hm"
          icon="AnchorIcon"
          color="warning"
          :statistic="kFormatter(getAnalytic.hulu_migas_gained.analytics_data.total_tambat)"
          statistic-title="Trafic Jetty SAL"
          :chart-data="getAnalytic.hulu_migas_gained.series"
        />
      </b-col>
    </b-row>
    <b-card body-class="p-0 custom-card">
      <b-row class="yo-row mb-1 p-2" align-h="between" align-v="center">
        <b-col cols="6">
          <b-form-group label="Choose Filter Month & Year" label-for="choose-month-year">
            <flat-pickr
              id="choose-month-year"
              v-model="monthyear"
              class="form-control"
              static="true"
              :config="dpconfig"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" v-if="isLoading">
          <span>
            <i>
              <strong>
                "Selamat datang di jetty SAL! Sementara data analitik kita dimuat dengan kecermatan oleh AI, bersiaplah untuk menemukan wawasan yang mendalam sebentar lagi."
                <!-- "Mengemudi ke dalam samudera data yang luas, AI kami bekerja dengan cermat untuk mengurai gelombang informasi yang kompleks di pelabuhan jeti SAL. Bersiaplah untuk menyelami wawasan yang mendalam saat kita memuat data analitik dengan presisi dan kecepatan yang mengagumkan." -->
              </strong>
            </i>
          </span>
          <hr>
          <b-progress
            animated
            variant="primary"
            :value="100"
            max="100"
            :class="'progress-bar-info'"
          >
          </b-progress>

        </b-col>
      </b-row>
    </b-card>
    <b-card body-class="p-0 custom-card">
      <div class="p-2">
        <GraphLineTambat :key="componentKey.statistic" :analytic="getAnalytic" />
      </div>
    </b-card>

    <CompLightTable
      :key="componentKey.table"
      ref="TableCountRiver"
      ref-specify="TableCountRiverSpec"
      :api-endpoint="apiEndpoint"
      sub-title-tbl=""
      :fields="fields"
      :new-data-template="newDataTemplate"
      :resolve-fetch-response="resolveFetchResponse"
      :resolve-fetch-url="resolveFetchUrl"
      :bottom-label="
      this.$t('KeteranganTable') + '~' + this.$t('Form Land Vehicle') + '~'
      "
      bottom-icon="GitMergeIcon"
      info-page-ability="Manage ( All & SAL ) only"
      placeholder-search="Kegiatan Name"
      :disableEdit="true"
      @render-component="renderComponent"
      :force-disable-crud="true"
      :no-footer="true"
    />

  </div>
</template>

<script>
import { kFormatter } from '@core/utils/filter'

import moment from 'moment'
import CompLightTable from '@/views/pages/components/BoatAnalytic.vue'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'

import AnalyticsCongratulation from '@views/pages/dashboard/analytics/AnalyticsCongratulation.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import GraphLineTambat from "./component/GraphLineTambat.vue";

export default {
  components: {
    CompLightTable,
    flatPickr,
    AnalyticsCongratulation,
    StatisticCardWithAreaChart,
    GraphLineTambat
  },
  props: {
    fhmID: {
      type: Number,
      required: false,
      default: null
    },
    permissionCrud: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      componentKey: {
        table: 1,
        statistic: 10,
        tbbm: 15,
        hm: 20,
      },
      apiEndpoint: `vw_riv_count_boat`,
      fields: [],
      monthyear: moment().format('yyyy-MM-DD'),
      dpconfig: {
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m-d",
          })
        ],
        altInput: true,
        altFormat: 'F Y',
        dateFormat: 'Y-m-d',
        // noCalendar: true,
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      analytic: [],
    }
  },
  watch: {
    // realtime checking
    // advanceSearchFetch
    'monthyear': function(value){
      console.log('monthyear::', value);
      this.componentKey.table += 1
      this.componentKey.statistic += 1
      this.componentKey.tbbm += 1
      this.componentKey.hm += 1
      // this.renderComponent()
    },
  },
  computed: {
    newDataTemplate() {
      return {
        jetty_position_id: null,
        jetty_position: {
          position: null
        },
        waktu_pindah_start: null,
        waktu_pindah_end: null
      }
    },
    getAnalytic: {
      get() {
        // this.item.children.forEach(item => {
        //   const { sandar_start_time } = item
        //   item.sandar_start_time = this.dateHumanReadAble(sandar_start_time)
        // });
        return this.analytic
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    moment,
    kFormatter,
    init() {
      console.log('init')
      this.initFields()
    },
    renderComponent() {
      console.log('[[ re render ]]')
      this.$emit('render-component', 'TableCountRiver')
    },
    initFields(dataku = null) {
      this.fields = [
        {
          searchModel: 'show_details',
          search: {
            type: 'text',
          },
          key: 'show_details',
          // stickyColumn: true,
          width: '10em',
          minWidth: '10em',
          label: 'Details',
          sortable: true,
          _showDetails: true,
          input: { type: 'input-noarea' },
        },
        {
          key: 'label',
          label: 'Boat Name',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'label',
          search: {
            type: 'text'
          },
          width: '16em',
          minWidth: '16em'
        },
        {
          key: 'total_items',
          label: 'Total Tambat di PT SAL',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'total_items',
          search: {
            type: 'text'
          },
          width: '16em',
          minWidth: '16em'
        }
      ]
    },
    resolveFetchResponse(response) {
      const { analytic } = response.data
      this.analytic = analytic
      this.componentKey.statistic += 1
      this.componentKey.tbbm += 1
      this.componentKey.hm += 1
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
      return response.data.vw_riv_count_boats
    },
    resolveFetchUrl({ pagination }) {
      this.isLoading = true;

      // Assuming you already have Moment.js imported as `moment`

      // Get the first day of the month
      const first_day_of_month = moment(this.monthyear).startOf('month').format('YYYY-MM-DD');

      // Get the last day of the month
      const last_day_of_month = moment(this.monthyear).endOf('month').format('YYYY-MM-DD');

      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
          first_day_of_month,
          last_day_of_month,

        }
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>